<template>
  <div>
    <titleLink :title="type === 0? '出团信息': type === 1? '突发事件信息': '游客信息'" :onClick="onClickBack"></titleLink>
    <div class="body">
      <van-pull-refresh v-model="isLoading" @refresh="refreshList" style="padding-bottom:2rem;margin-top:-30px">
        <van-list v-model="listLoading" :finished="listFinished" 
          :finished-text="list.length > 0? '没有更多了': ''" @load="loadList()">
          <div v-if="type === 0" style="margin-top:1rem">
            <tourCard :tour="item" v-for="item, idx in list" :key="idx"
              style="margin-top:.2rem" :clickable="false"></tourCard>
          </div>
          <div v-if="type === 1" style="margin-top:1rem">
            <card v-for="item, idx in list" :key="idx" style="margin-top:.2rem">
              <template #content>
                <tourInfoTop :tour="item.travel_list"></tourInfoTop>
                <van-divider/>
                <van-cell title="报告时间">
                  {{item.created_at}}
                </van-cell>
                <van-cell title="事件类型">
                  {{item.report_type_text}}
                </van-cell>
                <van-cell title="内容">
                  {{item.report_content}}
                </van-cell>
                <van-cell title="审核状态">
                  <van-tag color="#13C2C225" text-color="#13C2C2" round
                    v-if="item.is_approved && item.approve_status == 1">{{item.approve_status_text}}</van-tag>
                  <van-tag color="#4168EE12" text-color="#4168EE" round
                    v-if="item.is_approved && item.approve_status == 2">{{item.approve_status_text}}</van-tag>
                  <van-tag color="#6f6f6f23" text-color="#606060" round
                    v-if="!item.is_approved">待审批</van-tag>
                </van-cell>
              </template>
            </card>
          </div>
          <div v-if="type === 2" style="margin-top:1rem">
            <card v-for="item, idx in list" :key="idx" style="margin-top:.2rem">
              <template #content>
                <van-row style="line-height:15px">
                  <van-col span="24" >
                    <div>
                      <span class="list-title">{{item.name}}</span>
                    </div>
                  </van-col>
                </van-row>
                <van-row style="line-height:15px">
                  <van-col span="5">
                    <div>
                      <span class="list-item">性别：{{item.gender}}</span>
                    </div>
                  </van-col>
                  <van-col span="19" class="right">
                    <div>
                      <span class="list-item"> 身份证号：{{item.id_card}} </span>
                    </div>
                  </van-col>
                </van-row>
                <van-row style="line-height:15px">
                  <van-col span="12">
                    <div>
                      <span class="list-item">电话：{{item.mobile}}</span>
                    </div>
                  </van-col>
                  <van-col span="12" class="right">
                    <div>
                      <span class="list-item"> 年龄：{{item.age}} </span>
                    </div>
                  </van-col>
                </van-row>
                <van-divider/>
                <van-row>
                  <van-col span="5" class="list-title">参团：</van-col>
                  <van-col span="24" class="list-item">
                    <div v-for="tour, idx in item.travel_lists" :key="'t' + idx" >
                      <van-cell :title="tour.list_name">
                        <tourStatusTag :tour="tour"></tourStatusTag>
                        <template #label>
                          <van-row>
                            <van-col span="24">
                              开始：{{tour.start_date}}
                            </van-col>
                            <van-col span="24">
                              结束：{{tour.end_date}}
                            </van-col>
                          </van-row>
                        </template>
                      </van-cell>
                    </div>
                  </van-col>
                </van-row>
              </template>
            </card>
          </div>
        </van-list>
        <van-empty :description="`没有${type === 0? '出团信息': type === 1? '紧急事件信息': '游客信息'}`" v-if="list.length === 0"/>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import tourCard from "@/views/components/TourCard"
import card from "@/views/components/Card"
import titleLink from "@/views/components/TitleLink"
import tourInfoTop from '@/views/components/TourInfoTop.vue'
import api from "@/services/apis"
import tourStatusTag from '@/views/components/TourStatusTag'
export default {
  components: {
    titleLink, tourCard, card, tourInfoTop, tourStatusTag
  },
  data() {
    return {
      ids: "",
      list_ids: "",
      list: [],
      isLoading: false,
      listLoading: false,
      type: 0, // 0: travel_list 1: emergency_report 2: tourists
      typeParams: ['lists_ids', 'reports_ids', 'tourists_ids'],
      paging: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null
      }
    }
  },
  mounted() {
    this.type = this.$route.query.lists_ids != undefined? 0: 
      this.$route.query.reports_ids != undefined? 1: 2
    this.ids = this.$route.query[this.typeParams[this.type]]
    this.list_ids = this.$route.query.list_ids
    this.refreshList()
  },
  computed: {
    listFinished() {
      return this.paging.next_page === null
    }
  },
  methods: {
    onClickBack() {
      this.$router.go(-1)
    },
    refreshList() {
      this.isLoading = true
      this.requestListData().then(res => {
        this.isLoading = false
        if(res.status === 200) {
          if(this.type === 1) {
            this.list = res.emergencies
          } else {
            this.list = res.data
          }
          this.syncPaging(res)
        }
      })
    },
    loadList() {
      this.loadList = true
      this.requestListData().then(res => {
        this.listLoading = false
        if(res.status === 200) {
          res.data.forEach(ele => {
            this.list.push(ele)
          })
          this.syncPaging(res)
        }
      })
    },
    syncPaging(res) {
      this.paging.total_count = res.total_count
      this.paging.total_pages = res.total_pages
      this.paging.next_page = res.next_page
      this.paging.current_page = res.current_page
    },
    requestListData() {
      if(this.ids != null) {
        switch (this.type) {
          case 0:
            return api.get_travel_lists_from_ids(this.ids, this.paging.next_page)
          case 1:
            return api.emergency_reports_from_ids(this.ids, this.paging.next_page)
          case 2:
            return api.tourists_from_ids(this.ids, this.list_ids, this.paging.next_page)
          default: 
            break
        }
      }
    }
  }
}

</script>

<style scoped>
.body {
  padding: 0 .2rem 3rem .2rem;
  height: 100vh;
  margin-top: 1rem;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.list-title {
  font-size: 15px;
  font-weight: bold;
}
.list-item {
  font-size: 12px;
  color: #323334;
}
.right {
  text-align: right;
}
</style>