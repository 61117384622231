<template>
  <div>
    <van-button round :color="tourStateStyle.color"
      style="height:25px;margin-left:5px;width:60px;"
      size="small" >
      <span :style="{color: tourStateStyle.text_color}">{{tour.status_text}}</span>
    </van-button>
  </div>
</template>

<script>
export default {
  name: "TourStatusTag",
  props: {
    tour: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    tourStateStyle() {
      switch (this.tour.status) {
        case 0: return {color: "#4168EE12", text_color: "#4168EE"};
        case 1: return {color: "#4168EE", text_color: "#fff"};
        case 2: return {color: "#13C2C212", text_color: "#13C2C2"};
        case 3: return {color: "#6f6f6f23", text_color: "#606060"};
      }
      return ''
    }
  }
}
</script>

<style>

</style>